import { Helmet } from 'react-helmet-async';
import { IMAGE_URL } from '../../../../Utilities/APIs/APIs';

export function stripHtml(html) {
	const temporalDivElement = document.createElement('div');
	temporalDivElement.innerHTML = html;
	return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

function BlogMetaData({ singleBlog }) {
	const title = singleBlog?.name || 'Tanjil Ahmed Ruhullah';
	const descriptionHtml = singleBlog?.description || 'Blog description.';
	const descriptionText = stripHtml(descriptionHtml).substring(0, 160);
	const image = singleBlog?.image
		? `${IMAGE_URL}${singleBlog.image}`
		: `${IMAGE_URL}default-image.jpg`;

	return (
		<Helmet prioritizeSeoTags>
			<title>{title}</title>
			<meta
				name="description"
				content={descriptionText}
			/>

			<meta
				property="og:type"
				content="article"
			/>
			<meta
				property="og:title"
				content={title}
			/>
			<meta
				property="og:description"
				content={descriptionText}
			/>
			<meta
				property="og:image"
				content={image}
			/>

			<meta
				name="twitter:card"
				content="summary_large_image"
			/>
			<meta
				name="twitter:title"
				content={title}
			/>
			<meta
				name="twitter:description"
				content={descriptionText}
			/>
			<meta
				name="twitter:image"
				content={image}
			/>
		</Helmet>
	);
}

export default BlogMetaData;
