import { Route, Routes } from 'react-router-dom';

import './App.css';

//imported components
import Home from './Screens/Home/Home';
import Footer from './Sections/Footer/Footer';
import Navbar from './Sections/Navbar/Navbar';

import About from './Screens/About/About';
import Blogs from './Screens/Blogs/Blogs';
import GalleryPage from './Screens/Gallery/GalleryPage';
import GalleryDetails from './Screens/Gallery/galleryDetails/GalleryDetails';
import SingleBlog from './Screens/SingleBlog/SingleBlog';
import ScrollTop from './ScrollTop';

function App() {
	return (
		<>
			<ScrollTop />
			<Navbar />
			<Routes>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/about"
					element={<About />}
				/>
				<Route
					path="/blogs"
					element={<Blogs />}
				/>
				<Route
					path="/single-blog/:id"
					element={<SingleBlog />}
				/>
				<Route
					path="/gallery"
					element={<GalleryPage />}
				/>
				<Route
					path="/gallery/:id"
					element={<GalleryDetails />}
				/>
			</Routes>
			<Footer />
		</>
	);
}

export default App;
